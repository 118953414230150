.site-translator-page {
    display: flex;

}

.site-translator-page > *:first-child {
    margin-right: 15px
}
.site-translator-page > * {
    width: calc(50% - 7.5px);
}
