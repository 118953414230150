.list-pages ul li {
    list-style: none;
}

.list-pages form {
    position: relative;
}

@media screen and (min-width: 960px) {
    .submit-page__wrapper {
        position: fixed;
        bottom: 15px;
        right: 30px;
    }
}