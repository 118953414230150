.logs-view {
    max-height: 350px;
    min-height: 150px;
    overflow-y: auto;
    border: 1px solid var(--muted-color);
    padding: var(--spacing);
    margin-bottom: var(--spacing);
}

.logs-view__item {
    margin-bottom: calc(var(--spacing) / 3);
    padding-bottom: calc(var(--spacing) / 3);;

    font-size: calc(var(--font-size) / 1.4);
    border-bottom: 1px solid var(--muted-color);
}

.logs-view__item:last-child {
    border-bottom: 0;
}

.logs-view__item span {
    margin-right: calc(var(--spacing) / 2);
}

.logs-view__item--error span {
    color: red;
}

.logs-view__item--success span {
    color: green;
}

.logs-view__item--warning span {
    color: yellow;
}