.loader {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    z-index: 99;

    background: rgba(0,0,0,0.5);
}