
.pages-item-replace-options button {
    font-size: calc(var(--font-size) / 1.3);
    padding: calc(var(--form-element-spacing-vertical) / 2) calc(var(--form-element-spacing-horizontal) / 2);
}

.pages-item__add-options {
    width: fit-content;
}

.pages-item__remove-options {
    width: fit-content;
    height: fit-content;
}

.pages-item__row {
    align-items: flex-end;
}